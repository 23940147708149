.label-bold {font-weight: bold;}

.user-list-item h5 {font-size: 1em;}
.user-list-item h6 {font-size: 0.85em;}

.control-buttons {margin-right: 10px;}
label {font-weight: bold;}
.invalid {color: red;}
.election-table {font-size: 0.85em;}

.admin-checkbox {margin-left: 0; margin-top: 12px;}
.expiration-label {margin-top: 10px;}
.button-spacer {margin-right: 10px;}
.expired {color: red;}
